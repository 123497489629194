import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { LayerMaterial, Depth, Noise } from 'lamina';
import LaptopModel from './LPTP';

function Bg() {
return (
    <mesh scale={100}>
      <boxGeometry args={[10, 10, 10]}/>
      <LayerMaterial side={THREE.BackSide}>
        <Depth colorB="black" colorA="black" alpha={1} mode="normal" near={130} far={200} origin={[100, 100, -100]} />
        <Noise mapping="local" type="white" scale={1000} colorA="white" colorB="black" alpha={0.01} />
      </LayerMaterial>
    </mesh>
  )
}

function Laptop() {
  return (
    <Canvas camera={{ position: [-2, 1.5, 1.75] }}>
      <color attach="background" args={['black']} />
      <Bg />
      <OrbitControls target={[0, 0.4, 0]}/>
      <Suspense fallback={null}>
        <LaptopModel position={[0, 0, 0]} />
      </Suspense>
    </Canvas>
  );
}

export default Laptop;
