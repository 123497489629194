function Website() {
  return (
    <div className='website'>
      <img src='https://media.discordapp.net/attachments/704733563874181220/993681293730660406/cursed_brainworm.png?width=362&height=366' alt='Distorted anime girl' height='200px'/>
      <h2>hi im emi.</h2>
      <p>i am a <i>cool and funny</i> software developer currently working at <a href='https://sdgames.co.uk/'>SD GAMES</a>.</p>
      <p>i like making creative and interesting stuff hehe feel free to check it out below :&#41;</p>
      <h2>links</h2>
      <ul>
        <li><a href='https://twitter.com/emiipo_'>twitter</a></li>
        <li><a href='https://github.com/emiipo'>github</a></li>
        <li><a href='https://gitlab.com/emiipo'>gitlab</a></li>
        <li><a href='mailto:contact@emii.me'>email</a> <i className='wip'>(or just contact@emii.me)</i></li>
      </ul>
      <h2>projects</h2>
      <ul>
        <li>various <i>lil silly</i> games</li>
        <li>emii.me <i>hehe</i></li>
        <li>discord &amp; dragons</li>
        <li>scheduling app</li>
        <li>plans for different projects to come :&#41; </li>
      </ul>
      <h2>other?</h2>
      <ul>
        <li>registered a company because i can :D</li>
        <li>made an ai of myself</li>
        <li>brainworms</li>
        <li>i make funny <i>lil</i> memes</li>
      </ul>
      <p className='wip'>(this is still all wip so it will be updated as time goes)</p>
      <p className='todo'>&#47;/TODO: link up the projects, make cooler hehe</p>
      <footer>
        <p>© {new Date().getFullYear()} emii ltd</p>
      </footer>
    </div>
  );
}

export default Website;
