/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import './Scrollbar.css';
import { useRef } from 'react';
import { useGLTF, Edges, Html } from '@react-three/drei';
import Website from './Website';

export default function LaptopModel({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(process.env.PUBLIC_URL + '/LPTP.glb');
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.4, 0.23, 0]} rotation={[0, 0, -1.83]}>
        <mesh geometry={nodes.Cube001.geometry}>
          <meshStandardMaterial color='black' metalness={1} roughness={1} transparent />
          <Edges color='white'/>
        </mesh>
        <mesh geometry={nodes.Cube001_1.geometry}>
          <meshStandardMaterial color='black' metalness={1} roughness={1} transparent />
          <Html className="content" scale={0.1} position={[-0.7, -0.04, 0]} rotation-x={-Math.PI / 2} rotation-z={-Math.PI / 2} rotation-y={Math.PI}  transform occlude>
            <Website />
          </Html>
          <Edges color='white'/>
        </mesh>
      </group>
      <mesh geometry={nodes.Cube002.geometry}>
        <meshStandardMaterial color='black' metalness={1} roughness={1} transparent />
        <Edges color='white' />
      </mesh>
      <mesh geometry={nodes.Cube002_1.geometry}>
        <meshStandardMaterial color='black' metalness={1} roughness={1} transparent />
        <Edges color='white'/>
      </mesh>
    </group>
  );
}

useGLTF.preload(process.env.PUBLIC_URL + '/LPTP.glb')
