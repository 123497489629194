import './Main.css';
import Laptop from "./Laptop";

function App() {
  return (
    <div className='Main'>
      <Laptop />
    </div>
  );
}

export default App;
